import logo from "./logo.svg";
import "./App.css";
import { ethers } from "ethers";
import IERC20 from "./config/IERC20.json";
import IUser from "./config/IUser.json";
import pancakeRouterData from "./config/pancakeData.json";
import * as pancakeSDK from "@pancakeswap/sdk";
import { useState } from "react";
import React from "react";

const provider = new ethers.providers.JsonRpcProvider(
  "https://bsc-dataseed1.binance.org"
);
const providerT = new ethers.providers.JsonRpcProvider(
  "https://data-seed-prebsc-1-s1.binance.org:8545/"
);

const usdtAddr = "0x55d398326f99059fF775485246999027B3197955";
// const usdtAddr = "0x7ef95a0fee0dd31b22626fa2e10ee6a223f8a684";
const userContractAddr = "0xcb057A8F100Fc4878295816C4e1e643EDC9950d1";
const usdtDecimals = 18;
const factoryAddr = "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73";
const MAX = ethers.constants.MaxUint256;

const USDT = new pancakeSDK.Token(97, usdtAddr, 18);
const CAKE = new pancakeSDK.Token(
  97,
  "0xF9f93cF501BFaDB6494589Cb4b4C15dE49E85D0e",
  18
);
const defaultGasLimit = ethers.BigNumber.from(360000);
// const defaultGasPrice = ethers.BigNumber.from("5000000000");

const defaultState = {
  walletArr: [],
  generateAmount: "3",
  balance: {},
  balanceU: {},
  balanceToken: {},
  approveU: {},
  approveToken: {},
  importWallet: "注意用英文逗号分隔:  私钥1,私钥2,私钥3...",
  allPrivateKey:
    "生成钱包会清空现有列表,如需导入请在生成后导入,生成后及时保存钱包私钥,导出会导出列表中所有钱包,包括导入钱包",
  transferAmount: 0,
  transferTokenAddr: "输入自定义token地址",
  transferPrivateKey: "输入出账钱包私钥",
  transferGas: 0,
  withdrawAddr: "输入收款钱包地址",
  selectedWallet: [],
  buyAmountU: 0,
  buyAmountMin: 0,
  sellAmountToken: 0,
  sellAmountMin: 0,
  gasLimit: defaultGasLimit,
  gasPrice: "5000000000",
  isUser: false,
  isAdmin: false,
  metamask: false,
  userAddr: "",
  newUserAddr:"",
  adminPrivateKey:""
};

let _selectedWallet = [];

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentDidMount() {
    this.initWallet();

  }

  async initWallet() {
    window.injectProvider = new ethers.providers.Web3Provider(window.ethereum);
    // provider = window.provider;
  }
  //钱包登录
  async login() {
    if (window.ethereum) {
      await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      this.getUserInfo();
    }
  }

  async getUserInfo() {
    let accounts = await window.injectProvider.listAccounts();
    this.setState({ userAddr: accounts[0] });
    let c = new ethers.Contract(userContractAddr, IUser, providerT);
    let flag = await c.isUser(accounts[0]);
    let adminAddr = await c.admin();
    //如果是显示App部分
    if (flag) {
      if (accounts[0] == adminAddr) {
        console.log("是管理员")
        this.setState({ isAdmin: true, isUser: flag });
      } else {
        this.setState({ isUser: flag });
      }
    } else {
      alert("非注册用户.")
    }
    console.log(adminAddr);
    console.log(flag);
  }

  async generateWallet() {
    alert(
      "正在生成钱包,加密运算计算量庞大,生成数量多需要时间,请耐心等待.点击确定开始生成."
    );
    let _walletArr = [];
    for (let i = 0; i < this.state.generateAmount; i++) {
      const _wallet = new ethers.Wallet(
        ethers.Wallet.createRandom().privateKey,
        provider
      );
      _walletArr.push(_wallet);
      console.log(`正在生成第${i + 1}个钱包`);
    }
    this.setState({ walletArr: _walletArr });
  }

  // async checkApprove(_tokenAddr, _wallet) {
  //   // console.log(MAX.toString());
  //   const tokenContract = new ethers.Contract(_tokenAddr, IERC20, _wallet);
  //   const res = await tokenContract.allowance(_wallet.address, pancakeRouterData.address);
  //   console.log(res.toString() / 10 ** 18)
  // }

  async approveToken(_tokenAddr) {
    alert(
      "点击确定开始授权,为提高效率授权全部完成后会一次性刷新状态状态,请耐心等待."
    );
    for (let i = 0; i < this.state.selectedWallet.length; i++) {
      const _wallet = this.state.selectedWallet[i];
      const tokenContract = new ethers.Contract(_tokenAddr, IERC20, _wallet);
      const res = await tokenContract.approve(pancakeRouterData.address, MAX, {
        gasLimit: this.state.gasLimit
      });
      if (i == this.state.selectedWallet.length - 1) {
        await res.wait();
        this.checkApprove();
      }
    }
  }

  async checkApprove() {
    console.log("正在检查授权..");
    let _approveU = {};
    let _approveToken = {};

    const contractU = new ethers.Contract(usdtAddr, IERC20, provider);
    let contractToken;
    if (this.state.transferTokenAddr.slice(0, 2) == "0x") {
      contractToken = new ethers.Contract(
        this.state.transferTokenAddr,
        IERC20,
        provider
      );
    }

    for (let i = 0; i < this.state.walletArr.length; i++) {
      const approveU = await contractU.allowance(
        this.state.walletArr[i].address,
        pancakeRouterData.address,
        { gasLimit: this.state.gasLimit}
      );
      console.log(MAX.div("10").toString());
      console.log(approveU.toString());
      console.log(approveU.gte(MAX.div("10")));
      if (approveU.gte(MAX.div("10"))) {
        _approveU[this.state.walletArr[i].address] = "已批准";
      } else {
        _approveU[this.state.walletArr[i].address] = "未批准";
      }

      if (this.state.transferTokenAddr.slice(0, 2) == "0x") {
        const approveToken = await contractToken.allowance(
          this.state.walletArr[i].address,
          pancakeRouterData.address,
          { gasLimit: this.state.gasLimit}
        );
        console.log(MAX.div("10000000000000000").toString());
        console.log(approveToken.toString());
        console.log(approveToken.gte(MAX.div("10")));
        if (await approveToken.gte(MAX.div("10"))) {
          _approveToken[this.state.walletArr[i].address] = "已批准";
        } else {
          _approveToken[this.state.walletArr[i].address] = "未批准";
        }
      } else {
        _approveToken[this.state.walletArr[i].address] = "未输入Token";
      }

      this.setState({
        approveU: _approveU,
        approveToken: _approveToken,
      });
    }
  }

  // async getApproveSelected(_wallet) {
  //   const contractU = new ethers.Contract(usdtAddr, IERC20, provider);

  //   const balance = await _wallet.getBalance();
  //   const balanceU = await contractU.balanceOf(_wallet.address);
  //   let balanceToken = ethers.BigNumber.from("0");
  //   console.log("aaaaaaaaaaaaaaaaaa" + this.state.transferTokenAddr.slice(0, 2))
  //   if (this.state.transferTokenAddr.slice(0, 2) == "0x") {
  //     const contractToken = new ethers.Contract(this.state.transferTokenAddr, IERC20, provider);
  //     balanceToken = await contractToken.balanceOf(_wallet.address)
  //   };
  //   this.setState({
  //     balance: {
  //       ...this.state.balance,
  //       [_wallet.address]: (balance.toString() / (10 ** 18))
  //     },
  //     balanceU: {
  //       ...this.state.balanceU,
  //       [_wallet.address]: (balanceU.toString() / (10 ** 18))
  //     },
  //     balanceToken: {
  //       ...this.state.balanceToken,
  //       [_wallet.address]: (balanceToken.toString() / (10 ** 18))
  //     }
  //   })
  // }

  async getBalance() {
    let _balanceArr = {};
    let _balanceUArr = {};
    let _balanceTokenArr = {};
    const contractU = new ethers.Contract(usdtAddr, IERC20, provider);

    for (let i = 0; i < this.state.walletArr.length; i++) {
      const balance = await this.state.walletArr[i].getBalance();
      _balanceArr[this.state.walletArr[i].address] =
        balance.toString() / 10 ** 18;
      console.log("BNB", _balanceArr);
      const balanceU = await contractU.balanceOf(
        this.state.walletArr[i].address
      );
      _balanceUArr[this.state.walletArr[i].address] =
        balanceU.toString() / 10 ** 18;
      console.log("USDT", _balanceUArr);
      console.log(
        "aaaaaaaaaaaaaaaaaa" + this.state.transferTokenAddr.slice(0, 2)
      );
      if (this.state.transferTokenAddr.slice(0, 2) == "0x") {
        const contractToken = new ethers.Contract(
          this.state.transferTokenAddr,
          IERC20,
          provider
        );
        const balanceToken = await contractToken.balanceOf(
          this.state.walletArr[i].address
        );
        _balanceTokenArr[this.state.walletArr[i].address] =
          balanceToken.toString() / 10 ** 18;
      } else {
        _balanceTokenArr[this.state.walletArr[i].address] = 0;
      }

      this.setState({
        balance: _balanceArr,
        balanceU: _balanceUArr,
        balanceToken: _balanceTokenArr,
      });
    }
  }

  // async getBalanceSingle(_wallet) {
  //   const contractU = new ethers.Contract(usdtAddr, IERC20, provider);

  //   const balance = await _wallet.getBalance();
  //   const balanceU = await contractU.balanceOf(_wallet.address);
  //   let balanceToken = ethers.BigNumber.from("0");
  //   console.log("aaaaaaaaaaaaaaaaaa" + this.state.transferTokenAddr.slice(0, 2))
  //   if (this.state.transferTokenAddr.slice(0, 2) == "0x") {
  //     const contractToken = new ethers.Contract(this.state.transferTokenAddr, IERC20, provider);
  //     balanceToken = await contractToken.balanceOf(_wallet.address)
  //   };
  //   this.setState({
  //     balance: {
  //       ...this.state.balance,
  //       [_wallet.address]: (balance.toString() / (10 ** 18))
  //     },
  //     balanceU: {
  //       ...this.state.balanceU,
  //       [_wallet.address]: (balanceU.toString() / (10 ** 18))
  //     },
  //     balanceToken: {
  //       ...this.state.balanceToken,
  //       [_wallet.address]: (balanceToken.toString() / (10 ** 18))
  //     }
  //   })
  // }

  addSelectedWallet(_wallet) {
    _selectedWallet.push(_wallet);
    console.log(_selectedWallet);
    this.setState({
      selectedWallet: _selectedWallet,
    });
  }
  delSelectedWallet(_wallet) {
    for (let i = 0; i < _selectedWallet.length; i++) {
      if (_selectedWallet[i].address === _wallet.address) {
        _selectedWallet.splice(i, 1);
      }
    }
    console.log(_selectedWallet);
    this.setState({
      selectedWallet: _selectedWallet,
    });
  }
  exportPrivateKey() {
    let str = "";
    this.state.walletArr.map((_wallet) => {
      if (
        _wallet.address ===
        this.state.walletArr[this.state.walletArr.length - 1].address
      ) {
        str += `"` + _wallet.privateKey + `"`;
      } else {
        str += `"` + _wallet.privateKey + `",`;
      }
    });
    this.setState({ allPrivateKey: str });
  }

  importPrivateKey() {
    const str = `[${this.state.importWallet}]`;
    console.log(str);
    const arr = JSON.parse(str);
    let res = [];
    let x = 0;
    arr.map((key) => {
      const _wallet = new ethers.Wallet(key, provider);
      let r = true;
      this.state.walletArr.map((wallet) => {
        wallet.address === _wallet.address ? (r = false) : <></>;
      });
      r ? res.push(_wallet) : (x += 1);
    });
    x === 0 ? <></> : alert(`有${x}个重复项`);
    this.setState({
      walletArr: [...this.state.walletArr, ...res],
    });
  }

  async transferU() {
    alert(
      "点击确定开始转账,为提高效率,全部完成后会一次性刷新状态状态,请耐心等待."
    );
    const _wallet = new ethers.Wallet(this.state.transferPrivateKey, provider);
    const tokenContract = new ethers.Contract(usdtAddr, IERC20, _wallet);
    const oAmount = this.state.transferAmount;
    console.log(oAmount);
    let amount;
    if (oAmount >= 1) {
      amount = ethers.BigNumber.from("10")
        .pow(usdtDecimals)
        .mul(this.state.transferAmount);
    } else {
      amount = ethers.BigNumber.from((oAmount * 10 ** usdtDecimals).toString());
    }
    console.log(amount.toString());

    const balance = await tokenContract.balanceOf(_wallet.address);
    console.log(balance.lt(amount.mul(this.state.selectedWallet.length)));
    if (balance.lt(amount.mul(this.state.selectedWallet.length))) {
      alert("余额不足");
    } else {
      for (let i = 0; i < this.state.selectedWallet.length; i++) {
        console.log(this.state.gasPrice);
        const res = await tokenContract.transfer(
          this.state.selectedWallet[i].address,
          amount,
          { gasLimit: this.state.gasLimit}
        );
        if (i == this.state.selectedWallet.length - 1) {
          await res.wait();
          this.getBalance();
        }
      }
    }
  }

  async transferBNB() {
    alert(
      "点击确定开始转账,为提高效率,全部完成后会一次性刷新状态状态,请耐心等待."
    );
    console.log("1111");
    const _wallet = new ethers.Wallet(this.state.transferPrivateKey, provider);
    const oAmount = this.state.transferAmount;
    console.log(oAmount);
    let amount;
    console.log("2222");
    if (oAmount >= 1) {
      amount = ethers.BigNumber.from("10")
        .pow(usdtDecimals)
        .mul(this.state.transferAmount);
    } else {
      amount = ethers.BigNumber.from((oAmount * 10 ** usdtDecimals).toString());
    }
    console.log(amount.toString());

    const tx = {
      from: _wallet.address,
      value: amount,
      gasLimit: this.state.gasLimit,
    };
    const balance = await _wallet.getBalance();
    console.log(balance.lt(amount.mul(this.state.selectedWallet.length)));
    if (balance.lt(amount.mul(this.state.selectedWallet.length))) {
      alert("余额不足");
    } else {
      for (let i = 0; i < this.state.selectedWallet.length; i++) {
        tx.to = this.state.selectedWallet[i].address;
        const res = await _wallet.sendTransaction(tx);
        // await res.wait()
        // this.getBalanceSingle(this.state.selectedWallet[i])
        if (i == this.state.selectedWallet.length - 1) {
          await res.wait();
          this.getBalance();
        }
      }
    }
  }

  async withdrawToken(_tokenAddr) {
    alert(
      "点击确定开始归集,为提高效率,全部完成后会一次性刷新状态状态,请耐心等待."
    );
    for (let i = 0; i < this.state.selectedWallet.length; i++) {
      const _wallet = this.state.selectedWallet[i];
      const tokenContract = new ethers.Contract(_tokenAddr, IERC20, _wallet);

      const balance = await tokenContract.balanceOf(_wallet.address);
      if (balance.eq("0")) {
      } else {
        const amount = balance;
        const res = await tokenContract.transfer(
          this.state.withdrawAddr,
          amount,
          { gasLimit: this.state.gasLimit}
        );
        if (i == this.state.selectedWallet.length - 1) {
          await res.wait();
          this.getBalance();
        }
      }
    }
  }

  async withdrawBNB() {
    alert(
      "点击确定开始归集,为提高效率,全部完成后会一次性刷新状态状态,请耐心等待."
    );
    for (let i = 0; i < this.state.selectedWallet.length; i++) {
      const _wallet = this.state.selectedWallet[i];
      let _gas;
      if (this.state.transferGas >= 1) {
        _gas = ethers.BigNumber.from("10")
          .pow(usdtDecimals)
          .mul(this.state.transferGas);
      } else {
        _gas = ethers.BigNumber.from(
          (this.state.transferGas * 10 ** usdtDecimals).toString()
        );
      }

      const balance = await _wallet.getBalance();
      if (balance.lt(_gas)) {
        alert("余额不足");
      } else {
        const amount = balance.sub(_gas);
        const tx = {
          from: _wallet.address,
          value: amount,
          to: this.state.withdrawAddr,
          gasLimit: this.state.gasLimit,
        };
        const res = await _wallet.sendTransaction(tx);
        // await res.wait()
        // this.getBalanceSingle(this.state.selectedWallet[i])
        if (i == this.state.selectedWallet.length - 1) {
          await res.wait();
          this.getBalance();
        }
      }
    }
  }

  // async interaction(_wallet) {
  //   const res = await pancakeRouter.swapExactTokensForTokens(ethers.BigNumber.from("1000000000000000000"), ethers.BigNumber.from("29"), ["0x7ef95a0FEE0Dd31b22626fA2e10Ee6A223F8a684", "0xF9f93cF501BFaDB6494589Cb4b4C15dE49E85D0e"], "0xf233859bc77dd7239fd2a9cb39d0966e7774e395", "1665717985")
  //   console.log(await res);
  // }

  async swap(_tokenAddr, _payAmount, _receiveAmountMin, _side) {
    console.log(this.state.gasPrice);
    alert(
      "点击确定开始交易,为提高效率,全部完成后会一次性刷新状态状态,请耐心等待."
    );
    const tokenContract = new ethers.Contract(_tokenAddr, IERC20, provider);
    const usdtContract = new ethers.Contract(usdtAddr, IERC20, provider);
    const _decimals = await tokenContract.decimals();
    // await _decimals.wait();
    let _amount;
    let _amountMin;
    let pair = [];
    //买入
    if (_side === 0) {
      pair[0] = usdtAddr;
      pair[1] = _tokenAddr;

      if (_payAmount >= 1) {
        _amount = ethers.BigNumber.from("10").pow(usdtDecimals).mul(_payAmount);
      } else {
        _amount = ethers.BigNumber.from(
          (_payAmount * 10 ** usdtDecimals).toString()
        );
      }
      if (_receiveAmountMin >= 1) {
        _amountMin = ethers.BigNumber.from("10")
          .pow(_decimals)
          .mul(_receiveAmountMin);
      } else {
        console.log("执行到这里了");
        console.log(_decimals);
        console.log(typeof _decimals);
        console.log(_receiveAmountMin * 10 ** (await _decimals));
        console.log(typeof (_receiveAmountMin * 10 ** (await _decimals)));
        console.log((_receiveAmountMin * 10 ** (await _decimals)).toString());
        _amountMin = ethers.BigNumber.from(
          (_receiveAmountMin * 10 ** _decimals).toString()
        );
      }
    }
    //卖出
    else {
      pair[0] = _tokenAddr;
      pair[1] = usdtAddr;

      if (_payAmount >= 1) {
        _amount = ethers.BigNumber.from("10").pow(_decimals).mul(_payAmount);
      } else {
        _amount = ethers.BigNumber.from(
          (_payAmount * 10 ** _decimals).toString()
        );
      }
      if (_receiveAmountMin >= 1) {
        _amountMin = ethers.BigNumber.from("10")
          .pow(usdtDecimals)
          .mul(_receiveAmountMin);
      } else {
        _amountMin = ethers.BigNumber.from(
          (_receiveAmountMin * 10 ** usdtDecimals).toString()
        );
      }
    }

    for (let i = 0; i < this.state.selectedWallet.length; i++) {
      const _wallet = this.state.selectedWallet[i];
      const pancakeRouter = new ethers.Contract(
        pancakeRouterData.address,
        pancakeRouterData.abi,
        _wallet
      );
      let lastTime = Date.parse(new Date()) / 1000 + 900;
      let balance;
      //买入
      if (_side === 0) {
        balance = await usdtContract.balanceOf(_wallet.address);
      } //卖出
      else {
        balance = await tokenContract.balanceOf(_wallet.address);
      }
      if (balance.lt(_amount)) {
        alert(
          `${_wallet.address}余额不足,点击确定后将跳过这个账号继续进行下一笔交易`
        );
        continue;
      } else {
        const res = await pancakeRouter.swapExactTokensForTokens(
          _amount,
          _amountMin,
          pair,
          _wallet.address,
          lastTime,
          { gasLimit: this.state.gasLimit}
        );
        if (i == this.state.selectedWallet.length - 1) {
          await res.wait();
          this.getBalance();
        }
      }
    }
  }

  walletList() {
    let a = [];
    console.log("钱包列表刷新");
    console.log(this.state.walletArr);
    // for(let i=0;i<5;i++){
    //   a.push()
    // }
    if (this.state.walletArr.length > 0) {
      this.state.walletArr.map(async (wallet, index) => {
        a.push(
          <div className="walletSingle">
            <div className="single-id">
              <input
                type={"checkbox"}
                className={"selectSingle"}
                id={"id" + wallet.address}
                onClick={() => {
                  if (document.getElementById("id" + wallet.address).checked) {
                    this.addSelectedWallet(wallet);
                  } else {
                    this.delSelectedWallet(wallet);
                  }
                }}
              />
              {index + 1}
            </div>
            <div className="single-private">{wallet.privateKey}</div>
            <div className="single-column">{wallet.address}</div>
            <div className="single-balance">
              {this.state.balance[wallet.address]}
            </div>
            <div className="single-balance">
              {this.state.balanceU[wallet.address]}
            </div>
            <div className="single-balance">
              {this.state.balanceToken[wallet.address]}
            </div>
            <div className="single-approve">
              {this.state.approveU[wallet.address]}
            </div>
            <div className="single-approve-end">
              {this.state.approveToken[wallet.address]}
            </div>
          </div>
        );
      });
      return (
        <div className="walletListBox">
          <div className="walletSingle">
            <div className="single-id">
              <input
                type={"checkbox"}
                id={"checkboxAll"}
                onClick={() => {
                  const all = document.getElementsByClassName("selectSingle");
                  if (document.getElementById("checkboxAll").checked === true) {
                    for (let i = 0; i < all.length; i++) {
                      all[i].checked = true;
                    }
                    _selectedWallet = [...this.state.walletArr];
                    this.setState({
                      selectedWallet: this.state.walletArr,
                    });
                  } else {
                    for (let i = 0; i < all.length; i++) {
                      all[i].checked = false;
                    }
                    _selectedWallet = [];
                    console.log(_selectedWallet);
                    this.setState({
                      selectedWallet: _selectedWallet,
                    });
                  }
                }}
              />
              ID
            </div>
            <div className="single-private">私钥</div>
            <div className="single-column">地址</div>
            <div className="single-balance">BNB</div>
            <div className="single-balance">USDT</div>
            <div className="single-balance">自定义Token</div>
            <div className="single-approve">USDT授权</div>
            <div className="single-approve-end">Token授权</div>
          </div>
          {a}
          <div className="walletSingle-end"></div>
        </div>
      );
    }
    console.log(a);
    return <div className="walletListBox">钱包列表为空</div>;
  }

  async addUser() {
    if(this.state.userAddr!=""&&this.state.adminPrivateKey!=""){
      const _wallet = new ethers.Wallet(this.state.adminPrivateKey, providerT);
      const _userContract = new ethers.Contract(userContractAddr, IUser, _wallet);
      let _err = "";
      const tx = await _userContract
      .addUser(this.state.newUserAddr)
      .catch((err)=>{
        _err = err;
        alert("交易失败:用户可能已注册,或者输入的地址或私钥有误")
      });
      if(_err==""){
        alert("请求已发送,请等待网络确认")
        const res = await tx.wait()
        .then((_res)=>{
          console.log(_res);
          alert("注册成功");
        })
      }
    }else{
      alert("请输入正确的用户地址和管理员私钥")
    }
  }
  async delUser() {
    if(this.state.userAddr!=""&&this.state.adminPrivateKey!=""){
      const _wallet = new ethers.Wallet(this.state.adminPrivateKey, providerT);
      const _userContract = new ethers.Contract(userContractAddr, IUser, _wallet);
      let _err = "";
      const tx = await _userContract.delUser(this.state.newUserAddr)
      .catch((err)=>{
        _err = err;
        alert("交易失败:用户可能不存在,或者输入的地址或私钥有误")
      });
      if(_err==""){
        alert("请求已发送,请等待网络确认")
        const res = await tx.wait()
        .then((_res)=>{
          console.log(_res);
          alert("删除成功");
        })
      }
    }else{
      alert("请输入正确的用户地址和管理员私钥")
    }
  }

  render() {
    return (
      <>
        {!this.state.isUser ? (
          <div className="login" onClick={() => this.login()}>
            {this.state.userAddr == "" ? "Connect" : this.state.userAddr}
          </div>
        ) : (
          <div className="App">
            <header className="App-header">
              <div className="inputBox">
                <div className="title">批量生成钱包</div>
                <input
                  value={this.state.generateAmount}
                  onChange={(event) =>
                    this.setState({ generateAmount: event.target.value })
                  }
                ></input>
                <button
                  className="btnAll"
                  onClick={() => this.generateWallet()}
                >
                  生成钱包
                </button>
                <textarea
                  className="generateWarning"
                  value={this.state.allPrivateKey}
                  readOnly
                ></textarea>
                <button
                  className="btnAll"
                  onClick={() => this.exportPrivateKey()}
                >
                  导出私钥
                </button>
              </div>
              <div className="inputBox">
                <div className="title">导入钱包</div>
                <textarea
                  className="importArea"
                  value={this.state.importWallet}
                  onChange={(event) => {
                    this.setState({ importWallet: event.target.value });
                    console.log(this.state.importWallet);
                  }}
                ></textarea>
                <button
                  className="btnAll"
                  onClick={() => this.importPrivateKey()}
                >
                  导入
                </button>
              </div>
              <div className="inputBox">
                <div className="title">批量转账</div>
                {/* <div className="inputName">
                  gasPrice:
                  <input
                    value={(this.state.gasPrice / 10 ** 9).toString()}
                    onChange={(event) =>
                      this.setState({
                        gasPrice: (event.target.value * 10 ** 9).toString(),
                      })
                    }
                  ></input>
                </div> */}
                <div className="inputName">
                  GasLimit:
                  <input
                    value={this.state.gasLimit.toString()}
                    onChange={(event) =>
                      this.setState({
                        gasLimit: ethers.BigNumber.from(event.target.value),
                      })
                    }
                  ></input>
                </div>
                {/* <div className='inputName'>币种地址:<input value={this.state.transferTokenAddr} onChange={(event) => this.setState({ transferTokenAddr: event.target.value })}></input></div> */}
                <div className="inputName">
                  每份金额:
                  <input
                    value={this.state.transferAmount}
                    onChange={(event) =>
                      this.setState({ transferAmount: event.target.value })
                    }
                  ></input>
                </div>
                <div className="inputName">
                  出账私钥:
                  <input
                    value={this.state.transferPrivateKey}
                    onChange={(event) =>
                      this.setState({ transferPrivateKey: event.target.value })
                    }
                  ></input>
                </div>
                <div className="inputName">
                  选中钱包:
                  <input
                    value={this.state.selectedWallet.length}
                    readOnly
                  ></input>
                </div>
                <div className="inputName">
                  合计金额:
                  <input
                    value={
                      this.state.selectedWallet.length *
                      this.state.transferAmount
                    }
                    readOnly
                  ></input>
                </div>
                <button className="btnAll" onClick={() => this.transferBNB()}>
                  转BNB
                </button>
                <button className="btnAll" onClick={() => this.transferU()}>
                  转USDT
                </button>
              </div>
              <div className="inputBox">
                <div className="title">批量归集</div>
                {/* <div className="inputName">
                  gasPrice:
                  <input
                    value={(this.state.gasPrice / 10 ** 9).toString()}
                    onChange={(event) =>
                      this.setState({
                        gasPrice: (event.target.value * 10 ** 9).toString(),
                      })
                    }
                  ></input>
                </div> */}
                <div className="inputName">
                  GasLimit:
                  <input
                    value={this.state.gasLimit.toString()}
                    onChange={(event) =>
                      this.setState({
                        gasLimit: ethers.BigNumber.from(event.target.value),
                      })
                    }
                  ></input>
                </div>
                <div className="inputName">
                  收款地址:
                  <input
                    value={this.state.withdrawAddr}
                    onChange={(event) =>
                      this.setState({ withdrawAddr: event.target.value })
                    }
                  ></input>
                </div>
                <div className="inputName">
                  预留gas:
                  <input
                    value={this.state.transferGas}
                    onChange={(event) =>
                      this.setState({ transferGas: event.target.value })
                    }
                  ></input>
                </div>
                <div className="inputName">
                  选中钱包:
                  <input
                    value={this.state.selectedWallet.length}
                    readOnly
                  ></input>
                </div>
                <button className="btnAll" onClick={() => this.withdrawBNB()}>
                  BNB一键归集
                </button>
                <button
                  className="btnAll"
                  onClick={() => this.withdrawToken(usdtAddr)}
                >
                  USDT一键归集
                </button>
                <div className="inputName">
                  币种地址:
                  <input
                    value={this.state.transferTokenAddr}
                    onChange={(event) =>
                      this.setState({ transferTokenAddr: event.target.value })
                    }
                  ></input>
                </div>
                <button
                  className="btnAll"
                  onClick={() =>
                    this.withdrawToken(this.state.transferTokenAddr)
                  }
                >
                  自定义token一键归集
                </button>
              </div>
              <div className="inputBox">
                <div className="title">买入</div>
                {/* <div className="inputName">
                  gasPrice:
                  <input
                    value={(this.state.gasPrice / 10 ** 9).toString()}
                    onChange={(event) =>
                      this.setState({
                        gasPrice: (event.target.value * 10 ** 9).toString(),
                      })
                    }
                  ></input>
                </div> */}
                <div className="inputName">
                  GasLimit:
                  <input
                    value={this.state.gasLimit.toString()}
                    onChange={(event) =>
                      this.setState({
                        gasLimit: ethers.BigNumber.from(event.target.value),
                      })
                    }
                  ></input>
                </div>
                <div className="inputName">
                  购买币种地址:
                  <input
                    value={this.state.transferTokenAddr}
                    onChange={(event) =>
                      this.setState({ transferTokenAddr: event.target.value })
                    }
                  ></input>
                </div>
                <div className="inputName">
                  支付USDT金额:
                  <input
                    value={this.state.buyAmountU}
                    onChange={(event) =>
                      this.setState({ buyAmountU: event.target.value })
                    }
                  ></input>
                </div>
                <div className="inputName">
                  最小收到数量:
                  <input
                    value={this.state.buyAmountMin}
                    onChange={(event) =>
                      this.setState({ buyAmountMin: event.target.value })
                    }
                  ></input>
                </div>
                <div className="inputName">
                  选中钱包:
                  <input
                    value={this.state.selectedWallet.length}
                    readOnly
                  ></input>
                </div>
                <button
                  className="btnAll"
                  onClick={() => this.approveToken(usdtAddr)}
                >
                  批准USDT权限
                </button>
                <button
                  className="btnAll"
                  onClick={() =>
                    this.swap(
                      this.state.transferTokenAddr,
                      this.state.buyAmountU,
                      this.state.buyAmountMin,
                      0
                    )
                  }
                >
                  买入
                </button>
              </div>
              <div className="inputBox">
                <div className="title">卖出</div>
                {/* <div className="inputName">
                  gasPrice:
                  <input
                    value={(this.state.gasPrice / 10 ** 9).toString()}
                    onChange={(event) =>
                      this.setState({
                        gasPrice: (event.target.value * 10 ** 9).toString(),
                      })
                    }
                  ></input>
                </div> */}
                <div className="inputName">
                  GasLimit:
                  <input
                    value={this.state.gasLimit.toString()}
                    onChange={(event) =>
                      this.setState({
                        gasLimit: ethers.BigNumber.from(event.target.value),
                      })
                    }
                  ></input>
                </div>
                <div className="inputName">
                  出售币种地址:
                  <input
                    value={this.state.transferTokenAddr}
                    onChange={(event) =>
                      this.setState({ transferTokenAddr: event.target.value })
                    }
                  ></input>
                </div>
                <div className="inputName">
                  卖出Token数量:
                  <input
                    value={this.state.sellAmountToken}
                    onChange={(event) =>
                      this.setState({ sellAmountToken: event.target.value })
                    }
                  ></input>
                </div>
                <div className="inputName">
                  最小收到U金额:
                  <input
                    value={this.state.sellAmountMin}
                    onChange={(event) =>
                      this.setState({ sellAmountMin: event.target.value })
                    }
                  ></input>
                </div>
                <div className="inputName">
                  选中钱包:
                  <input
                    value={this.state.selectedWallet.length}
                    readOnly
                  ></input>
                </div>
                <button
                  className="btnAll"
                  onClick={() =>
                    this.approveToken(this.state.transferTokenAddr)
                  }
                >
                  批准Token权限
                </button>
                <button
                  className="btnAll"
                  onClick={() =>
                    this.swap(
                      this.state.transferTokenAddr,
                      this.state.sellAmountToken,
                      this.state.sellAmountMin,
                      1
                    )
                  }
                >
                  卖出
                </button>
              </div>
              {this.state.isAdmin?
              <div className="inputBox">
                <div className="title">注册</div>
                <div className="inputName">
                  用户地址:
                  <input
                    value={this.state.newUserAddr}
                    onChange={(event) =>
                      this.setState({
                        newUserAddr: event.target.value,
                      })
                    }
                  ></input>
                </div>
                <div className="inputName">
                  管理员私钥:
                  <input
                    value={this.state.adminPrivateKey}
                    onChange={(event) =>
                      this.setState({
                        adminPrivateKey: event.target.value,
                      })
                    }
                  ></input>
                </div>

                <button
                  className="btnAll"
                  onClick={() =>
                    this.addUser()
                  }
                >
                  登记用户
                </button>
                <button
                  className="btnAll"
                  onClick={() =>
                    this.delUser()
                  }
                >
                  移除用户
                </button>
              </div>:<></>}
            </header>
            <div>
              {/* <button className='btnAll'onClick={() => this.interaction(wallet)}>交互</button> */}
              <div className="inputName">
                自定义token地址:
                <input
                  value={this.state.transferTokenAddr}
                  onChange={(event) =>
                    this.setState({ transferTokenAddr: event.target.value })
                  }
                ></input>
              </div>
              <button className="btnAll" onClick={() => this.getBalance()}>
                查询余额
              </button>
              <button className="btnAll" onClick={() => this.checkApprove()}>
                查询授权
              </button>
            </div>
            {this.walletList()}
          </div>
        )}
      </>
    );
  }
}
